import { useState, useEffect } from "react";
import "./Document.css";
import Header from "../../components/Header/Header";
import Layout from "components/layout/layout";
import { 
  fetchDocuments,
  fetchProviders,
  fetchAllPatients,
  fetchGroups,
  fetchGroupTypes,
  updateGroupStatus
 } from "lib/api/service";
import Modal from 'react-modal';
import GroupModal from "./GroupModal";
import { transformPatientData, transformProviderData } from './utils';

import DocumentTab from "./DocumentTab/DocumentTab";

const DBTracker = () => {
  const [activeTab, setActiveTab] = useState("Individual");
  const [nestedTab, setNestedTab] = useState("current");
  const [users, setUsers] = useState({current: [], disCharged: []});
  const [showModal, setShowModal] = useState(false);

  const [providers, setProviders] = useState([]);
  const [patients, setPatients] = useState([]);
  const [groups, setGroups] = useState({ active: [], archived: [] });
  const [fetching, setFetching] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupTypes, setGroupTypes] = useState([]);

  const userRole = localStorage.getItem("userRole");

  const fetchUsers = async () => {
    const response = await fetchDocuments();
    const current = response.results.filter((user) => user.disCharge === 0);
    const disCharged = response.results.filter((user) => user.disCharge === 1);
    setUsers({current, disCharged});
  }

  const [contextMenu, setContextMenu] = useState([]);

  const fetchProvidersFunc = async () => {
    try {
      const response = await fetchProviders();
      const transformedData = transformProviderData(response);
      setProviders(transformedData);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAllPatientsFunc = async () => {
    try {
      const response = await fetchAllPatients();
      const transformedData = transformPatientData(response.patients);
      setPatients(transformedData);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchGroupsTypesFunc = async () => {
    const response = await fetchGroupTypes();
    setGroupTypes(response);
  };

  const fetchGroupsFunc = async () => {
    try {
      setFetching(true);
      const response = await fetchGroups();

      const active = response.filter((group) => group.active === 1);
      const archived = response.filter((group) => group.active === 0);

      setGroups({ active, archived });
    } catch (e) {
      console.error(e);
    }
    setFetching(false);

  };

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);

    if (tabId === "Individual") {
      setNestedTab("current");
    } else if (tabId === "Groups") {
      setNestedTab("active");
      fetchGroupsTypesFunc();
      fetchGroupsFunc();
    }
  };

  const handleNestedTabChange = (tabId) => {
    setNestedTab(tabId);
  };

  const hideModalAndResetGroup = () => {
    setShowModal(false);
    setSelectedGroup(null);
  }

  const saveAndFetch = (fetch) => {
    if (fetch) {
      fetchGroupsFunc();
    }
    hideModalAndResetGroup();
  }

  useEffect(() => {
    fetchUsers();
    if (userRole === 'SuperAdmin') {
      fetchProvidersFunc();
      fetchAllPatientsFunc();
    }
  }, []);

  useEffect(() => {
    setContextMenu([{
      id: 1,
      getLabel: () => 'Manage Group',
      showItem: (data) => data.active === 1,
      disabled: () => false,
      handler: (id) => {
        if (id) {
          const allGroups = [...groups.active, ...groups.archived];

          const currentGroup = allGroups.find((group) => group.id === id);

          setSelectedGroup(currentGroup);
          setShowModal(true);
        }
      }
    },
    {
      id: 2,
      getLabel: () => 'Download all files',
      showItem: () => true,
      disabled: (data) => data.documentation === 0,
      handler: (id) => {
        if (id) {
          const token = localStorage.getItem("token");

          window.open(`${process.env.REACT_APP_BASE_API_URL}/groups/download/${id}?token=${token}`, '_blank');
        }
      }
    },
    // Create Object with handler to allow Download of files
    {
      id: 3,
      getLabel: (data) => { 
        return data?.active === 1 ? 'Archive' : 'Move Back'
      },
      showItem: () => true,
      disabled: () => false,
      handler: async (id, active) => {
        await updateGroupStatus(id, active);

        fetchGroupsFunc();
      }
    }
    ]);

  }, [groups]);

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight bg-white">
        <Header pageTitle={"Documents"} />
        <div className="main-content">
          <div className="headingTable cardPaitent">
            <div id="tracker">
              <div className="headingTable mb-4 px-3">

                <div className="col-md-12">
                  <div className=" rounded-3 px-5 pt-4 pb-5">
                    <div className="row justify-content-center">
                      <div className="col-auto mt-3">
                        <ul class="nav nav-pills mb-3 navTabUI" id="pills-tab">
                          <li class="nav-item flex-fill bg-light rounded-start-3">
                            <button
                              type="button"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              className="nav-link active px-5 py-2 w-100"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                              onClick={() => handleTabChange("Individual")}
                            >
                              Individuals
                            </button>
                          </li>
                          <li class="nav-item flex-fill bg-light rounded-end-3">
                            <button
                              type="button"
                              className="nav-link px-5 py-2 w-100"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                              onClick={() => handleTabChange("Groups")}
                            >
                              Groups
                            </button>
                          </li>
                        </ul>

                      </div>
                    </div>

                    <DocumentTab
                      activeTab={activeTab}
                      nestedTab={nestedTab}
                      users={users}
                      groups={groups}
                      handleNestedTabChange={handleNestedTabChange}
                      setShowModal={setShowModal}
                      contextMenu={contextMenu}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={showModal} onRequestClose={hideModalAndResetGroup}
          className='documents-modal'>
          <GroupModal
            isCreate={true}
            providers={providers}
            patients={patients}
            groupTypes={groupTypes}
            saveAndFetch={saveAndFetch}
            selectedGroup={selectedGroup}
          />
        </Modal>

      </div>
    </Layout>
  );
};

export default DBTracker;
