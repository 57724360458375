import React, { useEffect, useMemo, useState } from "react";
import { Button, Image } from "react-bootstrap";
import Modal from "react-modal";
import "./Messages.css";
import user from "../../assets/images/user_online.png";
import Menu from "../../assets/images/menu.png";
import Calender from "../../assets/images/calender.png";
import SeheduleAppointmentModal from "modals/MessageModal/SeheduleAppointmentModal";
import axios from "axios";
import AddNewMessageModal from "modals/MessageModal/AddNewMessageModal";

const MessagesUserHeader = ({
  activeTab,
  userName,
  msgData,
  checkMsgType,
  allPartcipant,
  fetchgroup,
  setFetchgroup,
  setMsgData,
  socket,
  roomId,
  onlineUsers,
  userChatOpenId,
}) => {
  const userData = localStorage.getItem("sbCalls");
  const englishNameUser = localStorage.getItem("name");
  const userId = JSON.parse(userData)?.userId;
  const token = localStorage.getItem("token");
  const [doctor, setDoctor] = useState([]);
  const [patient, setPatient] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [groupMessage, setGroupMessage] = useState();
  const [openSeheduleAppointmentModal, setOpenSeheduleAppointmentModal] =
    useState(false);
  const [openAddMessageModal, setOpenAddMessageModal] = useState(false);

  const isUserOnline = (userId) => onlineUsers.has(userId);

  const handleSearch = async (e) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/users/allpatientsdoctors?search=${e?.target?.value ?? ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const doctors = response.data.doctors.map((doctor) => ({
        ...doctor,
        type: "Doctor",
      }));

      const patients = response.data.patients.map((patient) => ({
        ...patient,
        type: "Patient",
      }));

      setDoctor(doctors);
      setPatient(patients);
      setAllUser([...response.data.doctors, ...response.data.patients]);
    } catch (e) {
      console.error(e);
    }
  };
  const doctorData = useMemo(() => {
    return doctor?.map((d) => {
      return {
        value: [d?.id, `${d.firstName} ${d.lastName}`],
        label: `${d.firstName} ${d.lastName}`,
      };
    });
  }, [doctor]);

  const patientData = useMemo(() => {
    return patient?.map((d) => {
      return {
        value: [d?.id, `${d.firstName} ${d.lastName}`],
        label: `${d.firstName} ${d.lastName}`,
      };
    });
  }, [patient]);

  const combinedData = useMemo(() => {
    const combined = [];
    if (doctorData) {
      combined.push(...doctorData);
    }
    if (patientData) {
      combined.push(...patientData);
    }
    return combined;
  }, [doctorData, patientData]);

  useEffect(() => {
    handleSearch();
  }, []);

  const userRole = localStorage.getItem("userRole");

  return (
    <>
      <div className="chat_header">
        {msgData && (
          <>
            <div className="position-relative agent_logo">
              {checkMsgType === "groupMsg" ? (
                <img
                  src="/images/GroupProfile.png"
                  className="img-fluid"
                  alt="agent-online"
                />
              ) : (
                <img src={user} className="img-fluid" alt="agent-online" />
              )}
            </div>
            <div className="flex-grow-1 pl-3 chat_agent">
              <h4 className="mb-0 bold-text">{userName ?? "John Doe"}</h4>
              {checkMsgType === "groupMsg" ? (
                <h5
                  onClick={() => {
                    setOpenAddMessageModal(true);
                    setGroupMessage(allPartcipant);
                    // <p>{isUserOnline(userChatOpenId) ? 'Online' : 'Offline'}</p>
                  }}
                  className="cursor-pointer"
                >
                  {allPartcipant?.participants?.length} participants
                </h5>
              ) : (
                <p>{isUserOnline(userChatOpenId) ? "Online" : "Offline"}</p>
              )}
            </div>
            <div className="chat_bedroom d-flex gap-3">
              {activeTab === "THERAPISTS" && 
                !(checkMsgType === "groupMsg" && userRole === 'Patient') && (
                <button
                  variant="light"
                  className="btn_div"
                  onClick={() => setOpenSeheduleAppointmentModal(true)}
                >
                  <Image src={Calender} alt="img" />{" "}
                  <span className="btn_text">Schedule appointment</span>
                </button>
              )}

              <Button variant="light" className="p-0">
                <Image src={Menu} alt="img" />
              </Button>
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={openSeheduleAppointmentModal}
        onRequestClose={() => setOpenSeheduleAppointmentModal(false)}
      >
        <SeheduleAppointmentModal
          title="Schedule appointment"
          assessmentInfo={{
            setOpenSeheduleAppointmentModal: setOpenSeheduleAppointmentModal,
          }}
        />
      </Modal>

      <Modal
        isOpen={openAddMessageModal}
        onRequestClose={() => setOpenAddMessageModal(false)}
      >
        <AddNewMessageModal
          assessmentInfo={{
            setOpenAddMessageModal: setOpenAddMessageModal,
          }}
          patientDataUser={patientData}
          therapistData={doctorData}
          handleSearch={handleSearch}
          activeTabChat={activeTab}
          fetchgroup={fetchgroup}
          setFetchgroup={setFetchgroup}
          roomId={roomId}
          setMsgData={setMsgData}
          socket={socket}
          title={"Update Group"}
          groupMessage={groupMessage}
          allData={combinedData}
        />
      </Modal>
    </>
  );
};

export default MessagesUserHeader;
