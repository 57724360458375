import React, { useEffect, useState, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "components/Header/Header";
import "./Messages.css";
import MessageSidebar from "./MessageSidebar";
import MessageSend from "./MessageSend";
import MessagesUserHeader from "./MessagesUserHeader";
import MessageChatSection from "./MessageChatSection";
import Layout from "components/layout/layout";
import axios from "axios";
import SocketEvents from "utils/constant/SocketEvents";
import { useSocketContext } from "context/chatContext/SocketContext";
import {
  SendMessage,
  defaultMessageData,
  deletedefaultMessage,
  getBotMessageById,
  getBotUnreadCount,
  getChatListAll,
  getChatMsgData,
  getChatMsgDataAllUnRead,
  getGroupRooms,
  searchGroup,
  updateMessage,
} from "pages/lib/apis";
const Messages = () => {
  const userData = localStorage.getItem("sbCalls");
  const englishNameUser = localStorage.getItem("name");
  const userId = JSON.parse(userData)?.userId;
  const token = localStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("THERAPISTS");
  const [value, setValue] = useState();
  // const { popNotification, setPopNotification } = useConfigStore();
  // const fileInputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isCloseChat, setIsCloseChat] = useState(false);
  // const [opened, { open, close }] = useDisclosure(false);
  const [searching, setSearching] = useState(null);
  // const { classes } = useStyles();
  const [activeModal, setActiveModal] = useState(false);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  const [groupCount, setGroupCount] = useState({});

  const [botAllUnreadMsg, setBotAllUnreadMsg] = useState([]);

  const [msgType, setmsgType] = useState();
  const { setSocketConnection, socket, disconnectSocket } = useSocketContext();
  const {
    ADDROOM,
    MESSAGE,
    // SEND_MESSAGE,
    SENT_TYPING,
    EDIT_MESSAGE,
    TYPING,
    DELETE_MESSAGE,
    SEND_SEEN_MESSAGE,
    SEEN_MESSAGE,
    SEEN_MSG,
    NOTIFICATION,
    NOTIFICATION_USER,
  } = SocketEvents;
  const [doctor, setDoctor] = useState([]);
  const [patient, setPatient] = useState([]);

  const [msgData, setMsgData] = useState();
  const [typeMsg, setTypeMsg] = useState();
  const [typingstate, setTypingState] = useState();
  const [editData, setEditData] = useState();
  const [deleteMsgId, setDeleteMsgId] = useState();
  const [roomId, setRoomId] = useState();
  const [participantId, setParticipantId] = useState();
  const [uploadedurl, setUploadedurl] = useState();
  const [fetchgroup, setFetchgroup] = useState(false);
  const [singlechatPluseIcon, setSinglechatPluseIcon] = useState(false);
  const [friendName, setFreindName] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState();
  const [type, setType] = useState("send_msg");
  const [allMsg, setAllMsg] = useState({});
  const [notification, setNotification] = useState();
  const [checkMsgType, setCheckMsgType] = useState();
  const [activeAgent, setActiveAgent] = useState();
  const [unreadCounts, setUnreadCounts] = useState({});
  const [allPartcipant, setAllPartcipant] = useState([]);
  const [msgChange, setMsgChange] = useState();
  const [msgTyping, setMsgTyping] = useState();
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  const [userChatOpenId, setUserChatOpenId] = useState();

  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
    setActiveAgent(null);
    setMsgData(null);
  };
  const handleSearch = async (e) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/users/allpatientsdoctors?search=${e?.target?.value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const groupSearchList = await searchGroup(e?.target?.value);

    const patientGroups = [];
    const doctorGroups = [];

    if (groupSearchList?.data) {
      setFetchgroup(true);
      groupSearchList?.data?.data?.forEach((group) => {
        if (roomId === group.id) {
          setFreindName(group?.groupName);
          setAllPartcipant(group);
        }
      });

      groupSearchList?.data?.data?.forEach((group) => {
        if (group.groupType === "PATIENTS") {
          patientGroups.push(group);
        } else if (group.groupType === "THERAPISTS") {
          doctorGroups.push(group);
        }
      });
    }

    const doctors = response.data.doctors.map((doctor) => ({
      ...doctor,
      type: "Doctor",
    }));

    const patients = response.data.patients.map((patient) => ({
      ...patient,
      type: "Patient",
    }));
    // setDoctor(doctors);
    // setPatient(patients);

    // Sort groups by creation date in descending order
    patientGroups.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    doctorGroups.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setDoctor([...doctorGroups, ...doctors]);
    setPatient([...patientGroups, ...patients]);

    const combinedArray = [
      ...doctorGroups,
      ...doctors,
      ...patientGroups,
      ...patients,
    ];

    setValue(combinedArray);

    // const doctors = response.data.doctors.map((doctor) => ({
    //   ...doctor,
    //   type: "Doctor",
    // }));

    // const patients = response.data.patients.map((patient) => ({
    //   ...patient,
    //   type: "Patient",
    // }));

    // setDoctor(doctors);
    // setPatient(patients);
  };

  const fetchData = async (token, userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/users/allpatientsdoctors`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const groupList = await getGroupRooms(userId);

      const patientGroups = [];
      const doctorGroups = [];

      if (groupList?.data) {
        setFetchgroup(true);
        groupList?.data?.forEach((group) => {
          if (roomId === group.id) {
            setFreindName(group?.groupName);
            setAllPartcipant(group);
          }
        });

        groupList?.data?.forEach((group) => {
          if (group.groupType === "PATIENTS") {
            patientGroups.push(group);
          } else if (group.groupType === "THERAPISTS") {
            doctorGroups.push(group);
          }
        });
      }

      const doctors = response.data.doctors.map((doctor) => ({
        ...doctor,
        type: "Doctor",
      }));

      const patients = response.data.patients.map((patient) => ({
        ...patient,
        type: "Patient",
      }));
      // setDoctor(doctors);
      // setPatient(patients);

      // Sort groups by creation date in descending order
      patientGroups.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      doctorGroups.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setDoctor([...doctorGroups, ...doctors]);
      setPatient([...patientGroups, ...patients]);

      const combinedArray = [
        ...doctorGroups,
        ...doctors,
        ...patientGroups,
        ...patients,
      ];

      setValue(combinedArray);

      const chatMsgDataAll = await getChatMsgDataAllUnRead(userId);
      setNotification(chatMsgDataAll);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(token, userId);
  }, [token, userId, fetchgroup]);

  const doctorData = useMemo(() => {
    return doctor.map((d) => {
      if (d.groupName) {
        return {
          value: [d?.id, { d }],
          label: ` ${d.groupName}`,
        };
      } else {
        return {
          value: [d?.id, `${d.firstName} ${d.lastName}`],
          label: `${d.firstName} ${d.lastName}`,
        };
      }
    });
  }, [doctor]);

  const patientData = useMemo(() => {
    return patient.map((d) => {
      if (d.groupName) {
        return {
          value: [d?.id, { d }],
          label: ` ${d.groupName}`,
        };
      } else {
        return {
          value: [d?.id, `${d.firstName} ${d.lastName}`],
          label: `${d.firstName} ${d.lastName}`,
        };
      }
    });
  }, [patient]);

  useEffect(() => {
    if (userId && englishNameUser && !socket) {
      setSocketConnection(userId, englishNameUser);
    }

    return () => {
      disconnectSocket();
    };
  }, [disconnectSocket, englishNameUser, setSocketConnection, socket, userId]);

  const findIdByFullName = (fullName) => {
    // Find the object in the array that matches the fullName
    // const result = value.find(item =>
    //     item.firstName && item.lastName && `${item.firstName} ${item.lastName}` === fullName
    // );

    const result = value.find(
      (item) =>
        (item.firstName &&
          item.lastName &&
          `${item.firstName} ${item.lastName}` === fullName) ||
        (item.groupName && item.groupName === fullName)
    );

    // Return the id if a match is found, otherwise return null
    return result ? result.id : null;
  };

  const handleAddRoom = async (item, chatUser, searchType) => {
    const id = findIdByFullName(chatUser);
    setUserChatOpenId(id);
    setmsgType(searchType);
    const roomSearchId =
      searchType === "single" || searchType === "group"
        ? item?.id
        : item?.threadId;

    if (searchType !== "bot") {
      // Emit ADDROOM event
      socket?.emit(ADDROOM, roomSearchId);

      // Set roomId, participantId, freindName, and singlechatPluseIcon
      setRoomId(roomSearchId);
      setParticipantId(item.participants);
      setFreindName(chatUser);
      setIsCloseChat(true);
      setSinglechatPluseIcon(false);
      setIsHovered(false);
      const chatMsgDataAll = await getChatMsgData(roomId);
      setMsgData(chatMsgDataAll?.data);
    } else {
      setMsgData([]);
      socket?.emit(ADDROOM, userId);
      setRoomId(userId);
      setFreindName(chatUser);
      setIsHovered(false);
      setIsCloseChat(true);
      const botAllData = await getBotMessageById(userId);
      setMsgData(botAllData?.data);
    }
  };

  const handleAddRoomSocket = async (item, chatUser, socketType) => {
    if (!socket || !socket.connected) {
      console.error("Socket is not connected");
      return;
    }

    try {
      const resData = await getChatListAll(userId);
      if (resData && resData.data && resData.data.length > 0) {
        // setValue(resData.data[0]);
      } else {
        console.warn("No data received from getChatListAll");
      }

      if (notification && notification.data) {
        const relevantIndex = notification.data.findIndex((thread) => {
          return thread.some((message) => message.threadId === item.id);
        });

        if (relevantIndex !== -1) {
          const updatedNotification = [...notification.data];
          updatedNotification[relevantIndex] = [];
          // Assuming setNotification is the state setter for notification
          setNotification({ ...notification, data: updatedNotification });
        }
      } else {
        console.warn("Notification data is undefined");
      }

      socket.emit(SEND_SEEN_MESSAGE, { item, participantsLogin: userId });
    } catch (error) {
      console.error("Error in handleAddRoomSocket:", error);
    }
  };

  useEffect(() => {
    if (roomId) {
      const fetchChatMsgData = async () => {
        try {
          setMsgData([]);
          if (msgType === "bot") {
            const botAllData = await getBotMessageById(userId);
            setMsgData(botAllData?.data);
          } else {
            const chatMsgDataAll = await getChatMsgData(roomId);
            setMsgData(chatMsgDataAll?.data);
          }
        } catch (error) {
          console.error("Error fetching chat messages:", error);
        }
      };
      fetchChatMsgData();
    }
  }, [msgType, roomId, userId]);

  useEffect(() => {
    if (socket) {
      socket?.on("user-connected", (data) => {
        setOnlineUsers((prevUsers) => {
          // Convert prevUsers to a Set if it's not already one
          const updatedUsers = new Set(prevUsers);

          // If `data` is an object, make sure to handle its uniqueness appropriately
          const dataId = data.id; // Change this according to how you identify unique data

          // Check if the Set already has an item with the same unique property
          if (![...updatedUsers].some((user) => user === dataId)) {
            updatedUsers.add(data); // Add data if it's not already present
          }

          return updatedUsers;
        });
      
      });

      socket.on("user-disconnected", (userId) => {
      
        setOnlineUsers((prevUsers) => {
          const newUsers = new Set(prevUsers);
          newUsers.delete(userId);
          return newUsers;
        });
      });

      socket?.on(MESSAGE, async (datachat) => {
        setArrivalMessage({
          formSelf: false,
          id: datachat.id,
          message: datachat.message,
          attachment: datachat.attachment,
          threadId: datachat.threadId,
          senderId: datachat.senderId,
          participants: datachat?.participantId,
          senderName: datachat.senderName,
          isRead: datachat.isRead,
          isDelete: datachat.isDelete,
          createdAt: datachat.createdAt,
        });
        const chatMsgDataAll = await getChatMsgDataAllUnRead(userId);
        setNotification(chatMsgDataAll);
      });

      socket.on(SEEN_MSG, async (seenMsgData) => {
        const chatMsgDataAll = await getChatMsgData(seenMsgData);
        setMsgData(chatMsgDataAll.data);
      });

      socket?.on(TYPING, (datachat) => {
        setTypingState(datachat);
      });
      socket?.on(NOTIFICATION_USER, async () => {
        const chatMsgDataAll = await getChatMsgDataAllUnRead(userId);
        setNotification(chatMsgDataAll);
        const unreadBotMsgCount = await getBotUnreadCount({
          id: userId,
        });
        setBotAllUnreadMsg(unreadBotMsgCount);
      });
      socket.on(DELETE_MESSAGE, (deleteData) => {
        setDeleteMsgId(deleteData?.msgId);
      });
      socket.on(EDIT_MESSAGE, (editedData) => {
        setEditData(editedData);
      });

      return () => {
        socket.off(SEND_SEEN_MESSAGE);
        socket.off(NOTIFICATION_USER);
        socket.off(TYPING);
        socket.off(SEEN_MESSAGE);
        socket.off(DELETE_MESSAGE);
        socket.off(EDIT_MESSAGE);
        socket.off("user-connected");
      };
    }
  }, [socket]);

  useEffect(() => {
    const fetchDataSS = async () => {
      socket?.emit(SEND_SEEN_MESSAGE, {
        item: { id: roomId },
        participantsLogin: userId,
      });
      const chatMsgDataAll = await getChatMsgData(roomId);
      setMsgData(chatMsgDataAll?.data);

      if (roomId) {
        const chatMsgDataAllUser = await getChatMsgData(roomId);
        setMsgData(chatMsgDataAllUser?.data);
      }
    };
    if (roomId && socket) {
      fetchDataSS();
    }
  }, [
    SEEN_MSG,
    socket,
    arrivalMessage,
    roomId,
    SEND_SEEN_MESSAGE,
    userId,
    botAllUnreadMsg,
  ]);

  useEffect(() => {
    if (roomId === arrivalMessage?.threadId) {
      arrivalMessage && setMsgData((prev) => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage]);

  useEffect(() => {
    if (deleteMsgId) {
      setMsgData(
        msgData?.filter((item) => {
          return item.id !== deleteMsgId;
        })
      );
    }
  }, [deleteMsgId]);

  useEffect(() => {
    if (editData) {
      const msgs = [...msgData];
      msgs[editData.msgIndex].message = editData.msg;
      setMsgData(msgs);
    }
  }, [editData]);

  useEffect(() => {
    setTimeout(() => {
      setTypingState(false);
    }, 1200);
  }, [typingstate]);

  useEffect(() => {
    setTimeout(async () => {
      setMsgTyping(false);
      const saveTypeMessages = localStorage?.getItem(roomId);
      const saveTypeMessage = JSON?.parse(saveTypeMessages);

      if (
        roomId &&
        (saveTypeMessage !== null || undefined) &&
        Object?.keys(saveTypeMessage?.message)?.length !== 0 && // Check if saveTypeMessage is not empty
        (saveTypeMessage?.message?.htmlValue !== null ||
          saveTypeMessage?.message?.textValue != "/n")
      ) {
        await defaultMessageData({
          message: {
            htmlValue: saveTypeMessage?.message?.htmlValue,
            textValue: saveTypeMessage?.message?.textValue,
          },
          isSend: false,
          roomId: roomId,
          isSeen: false,
          senderId: userId,
        });
      }
    }, 3000);
  }, [msgTyping]);

  const handleChange = (event) => {
    const value = event.target.value;
    setTypeMsg(value);
  };

  const handleSendMsg = async () => {
    try {
      if (!Array.isArray(participantId)) {
        console.error("participantId must be an array");
        return;
      }

      if (
        typeMsg &&
        type === "edit_msg" &&
        typeMsg.message &&
        participantId !== null
      ) {
        const typedMsgdata = {
          message: typeMsg.message,
        };

        updateMessage(token, allMsg?.itemClick?.id, typedMsgdata)
          .then(async () => {
            if (typeMsg) {
              await deletedefaultMessage({
                roomId: roomId,
                senderId: userId,
              });

              localStorage?.removeItem(roomId);
              const updatedMsgData = [...msgData];
              updatedMsgData[allMsg?.itemIndex].message = typedMsgdata.message;
              setMsgData(updatedMsgData);

              const socketData = {
                roomId: roomId,
                msgIndex: allMsg?.itemIndex,
                msg: typedMsgdata.message,
              };

              socket?.emit(EDIT_MESSAGE, socketData);
              setTypeMsg("");
            }
          })
          .catch((err) => {
            console.error(err);
          });

        setType("send_msg");
        setTypeMsg(null);
        return;
      }

      if (typeMsg?.message && typeMsg.message.trim() && type === "send_msg") {
        const res = await SendMessage({
          message: typeMsg.message,
          attachment: uploadedurl,
          threadId: roomId,
          senderId: String(userId),
          senderName: englishNameUser,
          participants: participantId,
          isRead: false,
          isDelete: false,
          createdAt: new Date(),
        });

        if (res.data) {
          localStorage?.removeItem(roomId);
          socket?.emit("send_msg", {
            id: res.data.id,
            formSelf: true,
            message: typeMsg.message,
            attachment: uploadedurl,
            threadId: roomId,
            senderId: String(userId),
            senderName: englishNameUser,
            participants: participantId,
            isRead: false,
            isDelete: false,
            createdAt: new Date(),
          });

          setMsgData((prevMsgData) => {
            if (!Array.isArray(prevMsgData)) {
              console.error("prevMsgData is not an array", prevMsgData);
              return prevMsgData;
            }
            return [
              ...prevMsgData,
              {
                id: res.data.id,
                message: typeMsg.message,
                attachment: uploadedurl,
                threadId: roomId,
                senderId: String(userId),
                senderName: englishNameUser,
                participants: participantId,
                isRead: false,
                isDelete: false,
                createdAt: new Date(),
              },
            ];
          });

          setUploadedurl([]);
          setTypeMsg({ message: "", roomId: roomId });
         
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleKeyUp = async (event) => {
    setTypeMsg({ ...typeMsg, message: event?.target?.value });
    if (roomId) {
      socket?.emit(ADDROOM, roomId);

      socket?.emit(SENT_TYPING, roomId);
    }
    if (event?.key === "Enter" && !event?.shiftKey) {
      event.preventDefault();

      handleSendMsg();
    }
  };


  useEffect(() => {
    const saveMsg = async () => {
      await defaultMessageData({
        message: msgChange,
        isSend: false,
        roomId: roomId,
        isSeen: false,
        senderId: userId,
      });
    };
    if (msgChange && roomId) {
      setTypeMsg({
        roomId: roomId,
        message: msgChange,
      });

      const messageDatablankSpace = JSON?.stringify({
        roomId: roomId,
        message: msgChange,
      });
      localStorage?.setItem(roomId, messageDatablankSpace);

      saveMsg();
    }
  }, [msgChange]);

  useEffect(() => {
    if (socket) {
      socket?.emit(NOTIFICATION, { arrivalMessage, participantsLogin: userId });

      const notiifictionAll = async () => {
        const chatMsgDataAll = await getChatMsgDataAllUnRead(userId);
        setNotification(chatMsgDataAll);
        return chatMsgDataAll;
      };
      notiifictionAll();
    }
  }, [NOTIFICATION, NOTIFICATION_USER, arrivalMessage, socket, userId]);

  const fetchNotificationData = async (userDataId) => {
    const chatMsgDataAll = await getChatMsgDataAllUnRead(userDataId);
    const unreadBotMsgCount = await getBotUnreadCount({ id: userDataId });
    return { chatMsgDataAll, unreadBotMsgCount };
  };

  useEffect(() => {
    if (socket) {
      const notificationHandler = async () => {
        const { chatMsgDataAll, unreadBotMsgCount } =
          await fetchNotificationData(userId);
        setNotification(chatMsgDataAll);
        setBotAllUnreadMsg(unreadBotMsgCount);
      };

      socket.on("notification_all", notificationHandler);
      socket.on(NOTIFICATION_USER, notificationHandler);

      // Cleanup to avoid memory leaks
      return () => {
        socket.off("notification_all", notificationHandler);
        socket.off(NOTIFICATION_USER, notificationHandler);
      };
    }
  }, [NOTIFICATION_USER, socket, userId, arrivalMessage]);
  const groupUnreadByThread = (data) => {
    return data.reduce((acc, current) => {
      const threadId = current.threadId;
      if (!acc[threadId]) {
        acc[threadId] = [];
      }
      acc[threadId].push(current);
      return acc;
    }, {});
  };

  const calculateUnreadCount = (threadId, notificationData) => {
    const uniqueMessageIds = new Set();
    const userUnreadCounts = {};
    const groupUnreadCounts = {}; // To store unread counts for each group

    if (notificationData?.data && Array.isArray(notificationData.data)) {
      notificationData.data.forEach((thread) => {
      
        if (Array.isArray(thread)) {
          thread?.forEach((message) => {
            const receiverId = message.participants.find(
              (participantId) => participantId !== message.senderId
            );
            const senderId = message.senderId;
            const participants = message?.participants.map((p) => p.id) || [];

           

           
            if (message?.participants?.length >= 3) {
              if (!message?.isRead && !participants.includes(senderId)) {
                // Ensure participantId is a string
                groupUnreadCounts[message?.threadId] =
                  (groupUnreadCounts[message?.threadId] || 0) + 1;
              }
            }

            if (receiverId == threadId && !message?.isRead) {
              uniqueMessageIds.add(message?.senderId);
              if (message?.senderId) {
                userUnreadCounts[message.senderId] =
                  (userUnreadCounts[message.senderId] || 0) + 1;
              }
            }
          });
        }
      });
    }

    const totalUnreadCounts = uniqueMessageIds.size; // Count of unique message IDs
    return { totalUnreadCounts, userUnreadCounts, groupUnreadCounts };
  };


  useEffect(() => {
    // Calculate unread counts for all threads
    const allUnreadCounts = {};
    let totalUnreadCountData = 0;

    if (value && Array.isArray(value)) {
      value?.forEach((item) => {
        const threadId = item?.id;

        if (threadId || item?.groupType) {
          const {
            totalUnreadCounts: threadUnreadCount,
            userUnreadCounts,
            groupUnreadCounts,
          } = calculateUnreadCount(threadId, notification);

          setGroupCount(groupUnreadCounts);
          allUnreadCounts[threadId] = {
            totalUnreadCount: threadUnreadCount,
            userUnreadCounts,
          };

          totalUnreadCountData += threadUnreadCount;
        }
      });
    }

    // Update the state with the unread counts
    setUnreadCounts(allUnreadCounts);

    const botUnreadCount = Array.isArray(botAllUnreadMsg?.data)
      ? botAllUnreadMsg.data.length
      : 0;

    setTotalUnreadCount(totalUnreadCountData + botUnreadCount);
  }, [value, notification, botAllUnreadMsg]);


  useEffect(() => {
    socket?.on("send_msg_Alert", async (datachat) => {
      const chatMsgDataAll = await getChatMsgDataAllUnRead(userId);
      setNotification(chatMsgDataAll);
      const unreadBotMsgCount = await getBotUnreadCount({
        id: userId,
      });

      setBotAllUnreadMsg(unreadBotMsgCount);
    });
  }, [MESSAGE, arrivalMessage, socket]);

  const messageValueDefault = roomId === typeMsg?.roomId ? typeMsg : "";

  return (
    <Layout>
      <div className="h-100 w-100 mainBglight">
        <Header pageTitle={"Messages"} />
        <div className="main-content px-3">
          <Row className="bg-white rounded-top-3 p-3">
            <Col xs={12} className="d-flex align-items-center p-3 mb-4">
              <main className="w-100">
                <section>
                  <Row className="overflow-hidden">
                    <Col lg={4} className="p-0 message_sidebar_div">
                      <MessageSidebar
                        handleSelect={handleSelect}
                        doctorData={doctorData}
                        patientData={patientData}
                        activeTab={activeTab}
                        unreadCounts={unreadCounts}
                        groupCount={groupCount}
                        setValue={setValue}
                        handleAddRoom={handleAddRoom}
                        handleAddRoomSocket={handleAddRoomSocket}
                        setCheckMsgType={setCheckMsgType}
                        setSearching={setSearching}
                        handleSearch={handleSearch}
                        setAllPartcipant={setAllPartcipant}
                        setActiveAgent={setActiveAgent}
                        activeAgent={activeAgent}
                        fetchgroup={fetchgroup}
                        setFetchgroup={setFetchgroup}
                        roomId={roomId}
                        setMsgData={setMsgData}
                        socket={socket}
                        onlineUsers={onlineUsers}
                      />
                    </Col>
                    <Col lg={8} className="p-0">
                      <div className="header_border">
                        <MessagesUserHeader
                          activeTab={activeTab}
                          userName={friendName}
                          msgData={msgData}
                          checkMsgType={checkMsgType}
                          allPartcipant={allPartcipant}
                          fetchgroup={fetchgroup}
                          setFetchgroup={setFetchgroup}
                          setMsgData={setMsgData}
                          socket={socket}
                          roomId={roomId}
                          onlineUsers={onlineUsers}
                          userChatOpenId={userChatOpenId}
                        />
                      </div>
                      <div className="position-relative agent_list-height">
                        <div className="text-center py-2">
                          {/* <p className="date_text">18 Aug 2023 04:14PM</p> */}
                        </div>
                        <MessageChatSection msgData={msgData} userId={userId} />
                      </div>
                      {msgData && (
                        <div className="flex-grow-0 py-3 px-4">
                          <MessageSend
                            handleSendMessage={() => {
                              handleSendMsg();
                              setType("send_msg");
                            }}
                            messageValueDefault={messageValueDefault}
                            handleChange={handleChange}
                            // handleTypeMsg={handleTypeMsg}
                            handleKeyUp={handleKeyUp}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </section>
              </main>
            </Col>
          </Row>
        </div>
        {/* <Footer /> */}
      </div>
    </Layout>
  );
};

export default Messages;
