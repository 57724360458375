import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ProfileContext } from "../../../context/ProfileProvider";
import "./Sidebarr.css";
import { PatientIntakContext } from "context/PatientIntakeProvider";
import storage from "pages/lib/storage";
import {
  AdminRequestIcon,
  AssessmentsIcon,
  DiaryCardIcon,
  LogoutIcon,
  MessagesIcon,
  PatientBillingIcon,
  PatientIcon,
  ScheduleIcon,
  SettingIcon,
  TherapistAndAdminBillingIcon,
  TherapistAndAdminDocumentationIcon,
} from "utils/svg";

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useContext(ProfileContext);
  const { userId } = storage.getItem("sbCalls");
  const { emptyPatients } = useContext(PatientIntakContext);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const userRole = storage.getItem("userRole");

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const onLogout = () => {
    storage.removeItem("sbCalls");
    storage.removeItem("userRole");
    storage.removeItem("userData");
    storage.removeItem("token");
    storage.removeItem("name");
    storage.removeItem("userDetails");
    storage.removeItem("selectedClinician");
    logout();
    emptyPatients();
    navigate("/login");
  };

  if (!userId) {
    return null;
  }

  return (
    <div className="d-flex flex-column flex-shrink-0 sidebarMenu h-100 position-fixed text-center py-2 start-0 ms-2">
      <Link to="/" className="mb-5">
        <img src="/images/logo.svg" width={"50px"} alt="" />
      </Link>

      <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
        {userRole === "SuperAdmin" && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/all-patients"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/all-patients" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/all-patients")}
            >
              <PatientIcon />
              {activeLink === "/all-patients" ? <span>Patients</span> : null}
            </Link>
          </li>
        )}

        {(userRole === "Therapist" || userRole === "Doctor") && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/therapist-patients"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/therapist-patients" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/therapist-patients")}
            >
              <PatientIcon />
              {activeLink === "/therapist-patients" ? (
                <span>Patients</span>
              ) : null}
            </Link>
          </li>
        )}


        {userRole === "Patient" && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/patients-assessments"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/patients-assessments" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/patients-assessments")}
            >
              <AssessmentsIcon />
              {activeLink === "/patients-assessments" ? (
                <span>Assessment</span>
              ) : null}
            </Link>
          </li>
        )}

        {userRole === "SuperAdmin" && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/requests"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/requests" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/requests")}
            >
              <AdminRequestIcon activeLink={activeLink} />
              {activeLink === "/requests" ? <span>Requests</span> : null}
            </Link>
          </li>
        )}

        {(userRole === "Doctor" || userRole === "SuperAdmin") && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/document"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/document" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/document")}
            >
              <TherapistAndAdminDocumentationIcon activeLink={activeLink} />
              {activeLink === "/document" ? <span>Document</span> : null}
            </Link>
          </li>
        )}

        {userRole === "Patient" && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/diarycard"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/diarycard" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/diarycard")}
            >
              <DiaryCardIcon activeLink={activeLink} />
              {activeLink === "/diarycard" ? (
                <span
                  style={{
                    marginTop: "5px",
                    whiteSpace: "nowrap",
                  }}
                >
                  DBT Tracker
                </span>
              ) : null}
            </Link>
          </li>
        )}

        <li className="nav-item mb-1 position-relative">
          <Link
            to="/messages"
            className={`nav-link py-2 bg-transparent ${
              activeLink === "/messages" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("/messages")}
          >
            <MessagesIcon activeLink={activeLink} />
            {activeLink === "/messages" ? <span>Messages</span> : null}
          </Link>
        </li>

        {(userRole === "SuperAdmin" || userRole === "Doctor") && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/adminBilling"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/adminBilling" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/adminBilling")}
            >
              <TherapistAndAdminBillingIcon activeLink={activeLink} />
              {activeLink === "/adminBilling" ? <span>Billing</span> : null}
            </Link>
          </li>
        )}

        {userRole === "Patient" && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/patientBilling"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/patientBilling" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/patientBilling")}
            >
              <PatientBillingIcon activeLink={activeLink} />
              {activeLink === "/patientBilling" ? <span>Billing</span> : null}
            </Link>
          </li>
        )}

        <li className="nav-item mb-1 position-relative">
          <Link
            to="/schedule"
            className={`nav-link py-2 bg-transparent ${
              activeLink === "/schedule" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("/schedule")}
          >
            <ScheduleIcon />
            {activeLink === "/schedule" ? <span>Schedule</span> : null}
          </Link>
        </li>

        <li className="nav-item mb-1 position-relative">
          <Link
            to="/settings"
            className={`nav-link py-2 bg-transparent ${
              activeLink === "/settings" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("/settings")}
          >
            <SettingIcon />
            {activeLink === "/settings" ? <span>Settings</span> : null}
          </Link>
        </li>

        {userRole === "SuperAdmin" && (
          <li className="nav-item mb-1 position-relative">
            <Link
              to="/usermanagement"
              className={`nav-link py-2 bg-transparent ${
                activeLink === "/usermanagement" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("/usermanagement")}
            >
              <PatientIcon />
              {activeLink === "/usermanagement" ? <span>Users</span> : null}
            </Link>
          </li>
        )}

        <div className="position-absolute bottom-0">
          <li className="nav-item mb-1 position-relative">
            <Link onClick={onLogout} className={`nav-link px-4 bg-transparent`}>
              <LogoutIcon />
            </Link>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default SideBar;
